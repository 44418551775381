import React from 'react';
// import Gallery from '../components/Gallery';
import Seo from '../components/SEO';
import { graphql, useStaticQuery } from 'gatsby';

import Gallery from '@browniebroke/gatsby-image-gallery';

function Lookbook() {
  const images = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { glob: "photography/*" } }) {
        edges {
          node {
            childImageSharp {
              thumb: gatsbyImageData(placeholder: BLURRED)
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  // can have width + height props inside gatsbyImageData object
  const photos = images.allFile.edges.map(({ node }) => node.childImageSharp);
  // `images` is an array of objects with `thumb` and `full`

  return (
    // <div className="flex flex-col items-center justify-center">
    <div
      className="mt-16 md:mt-20 lg:mt-12"
    >
      <Seo pageTitle="lookbook" />
      <Gallery images={photos} />
    </div>
  );
}

export default Lookbook;
